import React, { useState } from 'react';
import { Container, Form, InputGroup, Image, Button } from 'react-bootstrap';

function App() {
  const [videoId, setVideoId] = useState('');
  const [thumbnails, setThumbnails] = useState(null);

  const extractVideoId = (url) => {
    // const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const id = extractVideoId(videoId);
    if (id) {
      setThumbnails({
        id,
        default: `default`,
        medium: `mqdefault`,
        high: `hqdefault`,
        standard: `sddefault`,
        maxres: `maxresdefault`,
      });
    }
  };

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center min-vh-100 mt-5">
      <h1 className="text-center">YouTube Thumbnail Grabber</h1>
      <Form onSubmit={handleSubmit} className="w-100" style={{ maxWidth: '600px' }}>
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            placeholder="Enter YouTube video URL"
            value={videoId}
            onChange={(e) => setVideoId(e.target.value)}
          />
          <Button variant="primary" type="submit">
            Get Thumbnails
          </Button>
        </InputGroup>
      </Form>

      {thumbnails && (
        <div className="text-center w-100">
          <p>Right-click on an image and select "Save Image As..." to download it.</p>
          <div className="mb-4">
            <h5>Default Quality</h5>
            <Image src={`https://i1.ytimg.com/vi/${thumbnails.id}/default.jpg`} fluid />
          </div>
          <div className="mb-4">
            <h5>Medium Quality</h5>
            <Image src={`https://i1.ytimg.com/vi/${thumbnails.id}/mqdefault.jpg`} fluid />
          </div>
          <div className="mb-4">
            <h5>High Quality</h5>
            <Image src={`https://i1.ytimg.com/vi/${thumbnails.id}/hqdefault.jpg`} fluid />
          </div>
          <div className="mb-4">
            <h5>Standard Quality</h5>
            <Image src={`https://i1.ytimg.com/vi/${thumbnails.id}/sddefault.jpg`} fluid />
          </div>
          <div className="mb-4">
            <h5>Max Resolution</h5>
            <Image src={`https://i1.ytimg.com/vi/${thumbnails.id}/maxresdefault.jpg`} fluid />
          </div>
        </div>
      )}
      <div className="w-100 mt-5" style={{ maxWidth: '800px' }} >
  <h2>What is YouTube Thumbnail Grabber Used For?</h2>
  <p>
    This YouTube Thumbnail Downloader is designed to help users easily extract and download thumbnails from any YouTube video. Whether you're preparing a presentation, working on an animation project, or engaging in other creative activities, this tool allows you to access high-quality thumbnails quickly and efficiently.
  </p>

  <h2>How Do I This YouTube Thumbnail Downloader?</h2>
  <p>
    Using this YouTube thumbnail downloader is simple and straightforward. Here’s a quick guide:
  </p>
  <ol>
    <li>
      <strong>Copy the YouTube Video URL:</strong> Start by copying the link of the YouTube video whose thumbnail you want to download.
    </li>
    <li>
      <strong>Paste the URL into the Input Box:</strong> Once you paste the URL into the input box on this website, it will automatically generate thumbnails in various sizes.
    </li>
    <li>
      <strong>Download the Thumbnails:</strong> To download, simply right-click on the desired thumbnail (or press and hold if you are using a mobile device) and select "Save Image As..." to save it.
    </li>
  </ol>

  <h2>Is It Legal to Download YouTube Thumbnails?</h2>
  <p>
    Yes, downloading YouTube thumbnails is generally legal. However, it's important to remember that both the thumbnails and videos are copyrighted materials. If you intend to reuse a thumbnail, especially for commercial purposes, it’s advisable to seek permission from the original content creator.
  </p>

  <h2>Are There Any Copyright Risks When Downloading YouTube Thumbnails?</h2>
  <p>
    The thumbnails you download are the intellectual property of the respective video owners. If you plan to use a thumbnail in your projects, particularly on YouTube or other public platforms, it’s essential to obtain the necessary permissions to avoid potential copyright issues. For personal use, such as website design or Photoshop projects, the risks are generally minimal, but caution is always advised.
  </p>

  <h2>Is Reusing YouTube Thumbnails SEO-Friendly?</h2>
  <p>
    Reusing YouTube thumbnails as they are is not SEO-friendly. Since Google indexes all YouTube thumbnails, reusing them without modification could lead to duplicate content issues. To make a thumbnail SEO-friendly, consider editing it with unique effects or modifications using software like Photoshop. This way, the thumbnail can be seen as unique content by search engines.
  </p>
</div>

    </Container>
  );
}

export default App;
